


























import { defineComponent, reactive } from '@vue/composition-api'

export default defineComponent({
  components: { OTopicsList: () => import('@/components/organisms/event/abstractModule/o-topics-list.vue')},

  setup(){
    const state = reactive({
      search: ''
    })

    return { state }
  }
})
